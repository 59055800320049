* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

body,
html {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}